import { Icon as Mui_Icon } from "@mui/material";
import { ComponentProps, forwardRef } from "react";

export type Type_Icon_Variants = "thin" | "light" | "regular" | "solid" | "kit";
export type Type_Props_Icon = ComponentProps<typeof Mui_Icon> & {
    variant: Type_Icon_Variants | string;
    icon: string;
    spin?: boolean;
    spinPulse?: boolean;
};

export const Icon = forwardRef<HTMLSpanElement, Type_Props_Icon>(
    (
        {
            variant = "light",
            icon,
            spin,
            spinPulse,
            className,
            ...props
        }: Type_Props_Icon,
        ref,
    ) => {
        const baseClassName = `fa-${variant} fa-${icon}`;
        const spinClassName = spin ? " fa-spin" : "";
        const spinPulseClassName = spinPulse ? " fa-spin-pulse" : "";
        const additionalClassName = className ? ` ${className}` : "";

        const iconClassName = `${baseClassName}${spinClassName}${spinPulseClassName}${additionalClassName}`;

        return <Mui_Icon className={iconClassName} {...props} ref={ref} />;
    },
);

Icon.displayName = "Icon";
