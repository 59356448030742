import { useTheme } from "@mui/material";

function getRGB(c: string): number {
    return (parseInt(c, 16) || c) as number;
}

function getsRGB(c: string): number {
    return getRGB(c) / 255 <= 0.03928
        ? getRGB(c) / 255 / 12.92
        : Math.pow((getRGB(c) / 255 + 0.055) / 1.055, 2.4);
}

function getLuminance(hexColor: string): number {
    return (
        0.2126 * getsRGB(hexColor.substring(1, 3)) +
        0.7152 * getsRGB(hexColor.substring(3, 5)) +
        0.0722 * getsRGB(hexColor.substring(5, 7))
    );
}

function getContrast(f: string, b: string): number {
    const L1 = getLuminance(f);
    const L2 = getLuminance(b);
    return (Math.max(L1, L2) + 0.05) / (Math.min(L1, L2) + 0.05);
}

export function invertColor(
    bgColor: string | null,
    darkColor?: string,
    whiteColor?: string,
): string {
    const theme = useTheme();

    if (!darkColor) {
        darkColor = theme.palette.text.primary;
    }
    if (!whiteColor) {
        whiteColor = theme.palette.common.white;
    }

    const whiteContrast = getContrast(
        bgColor ?? theme.palette.common.white,
        theme.palette.common.white,
    );
    const blackContrast = getContrast(
        bgColor ?? theme.palette.common.black,
        theme.palette.common.black,
    );

    return whiteContrast > blackContrast ? whiteColor : darkColor;
}

export const stringToColor = (string: string) => {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
        hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = "#";

    for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
    return color;
};

// Permet d'obtenir une atténuation d'une couleur selon un pourcentage (0.00 >= x <= 1.00)
export const lightenColor = (
    hexColor: string,
    percent: number,
    applyTransparency: boolean = false, // Par défaut, pas de transparence
): string => {
    // Enlève le "#", si présent
    hexColor = hexColor.startsWith("#") ? hexColor.slice(1) : hexColor;

    // Convertie les parties RGB
    const num: number = parseInt(hexColor, 16);
    const r: number = (num >> 16) + Math.round((255 - (num >> 16)) * percent);
    const g: number =
        ((num >> 8) & 0x00ff) +
        Math.round((255 - ((num >> 8) & 0x00ff)) * percent);
    const b: number =
        (num & 0x0000ff) + Math.round((255 - (num & 0x0000ff)) * percent);

    const newR: number = Math.min(255, r);
    const newG: number = Math.min(255, g);
    const newB: number = Math.min(255, b);

    if (applyTransparency) {
        const alpha = Math.round(percent * 255);
        return `#${((1 << 24) + (newR << 16) + (newG << 8) + newB)
            .toString(16)
            .slice(1)
            .toUpperCase()}${alpha.toString(16).toUpperCase().padStart(2, "0")}`;
    }

    // Retourner la couleur en format hexadécimal sans transparence
    return `#${((1 << 24) + (newR << 16) + (newG << 8) + newB).toString(16).slice(1).toUpperCase()}`;
};
