import frFR from "./fr_FR.json";
import enGB from "./en_GB.json";
import enUS from "./en_US.json";
import esES from "./es_ES.json";
import deDE from "./de_DE.json";
import arMA from "./ar_MA.json";
import nlNL from "./nl_NL.json";
import { getLocalStorageItem } from "src/utils/localStorageServices";
import { LANGUAGE_NAME_DIVIDER } from "src/api/formatters";
import { DEFAULT_LANGUAGE } from "src/contexts/languages";

// Pour chaque ajout de nouvelles langues :
// 1 -- Créer et importer le fichier .json (ex: fr_FR.json) en haut de la page;
// 2 -- Compléter le tableau "countryCodeLanguage" en rajoutant le code du pays précisé sur le lien.
// 3 -- Compléter l'object associatif "languagesList", avec le countryCode et le nom complet du pays dans sa langue.
// 4 -- Compléter l'object associatif "languageFiles"; avec le countryCode et le nom du fichier .json.
// 5 -- Compléter l'array associatif "languageLocaleMap"; en key le countryCode avec l'export correspondant dayjs/locale/xx.js.
// - -- Ajouter cette export ici 'src/configurations/LocalizationWrapper.tsx'

// Tableau de deux lettres représentant un payse selon :
// https://docwiki.embarcadero.com/RADStudio/Sydney/fr/Noms_Langue-Culture,_codes_et_valeurs_ISO

export type Type_CountryCode = string;

export const countryCodeLanguageLtr: Type_CountryCode[] = [
    "fr_FR",
    "en_GB",
    "en_US",
    "de_DE",
    "es_ES",
    "nl_NL",
];
export const countryCodeLanguageRtl: Type_CountryCode[] = ["ar_MA"];
export const countryCodeLanguages: Type_CountryCode[] = [
    ...countryCodeLanguageLtr,
    ...countryCodeLanguageRtl,
];

export const isRtlLang = (): boolean => {
    return countryCodeLanguageRtl.includes(
        getLocalStorageItem("language") || DEFAULT_LANGUAGE,
    );
};

// - Object d'associations des countryCodeLanguage avec le nom du pays en entier dans sa langue.
export type Message = string | Type_LanguagesList;

export type Type_LanguagesList = {
    [x: string]: Message;
};

export const languagesList: Type_LanguagesList = {
    fr_FR: "French",
    de_DE: "German",
    en_GB: "English",
    en_US: "EnglishUnitedStates",
    es_ES: "Spanish",
    ar_MA: "Arabic",
    nl_NL: "Dutch",
};

export const languages: Type_LanguagesList = {
    fr_FR: "Français",
    de_DE: "Deutsch",
    en_GB: "English",
    en_US: "English (United States)",
    es_ES: "Español",
    ar_MA: "العربية",
    nl_NL: "Nederlands",
};

/*
 * Utilisation pour l'attribut adapterLocale de LocalizationProvider.
 * Pour l'ajout d'une langue, vérifier la valeur à attribuer à la key dans les imports de dayjs/locale/xx.js
 * Voir la conf : src/configurations/LocalizationWrapper.tsx
 */
export const languageLocaleMap: { [x: string]: string } = {
    fr_FR: "fr",
    de_DE: "de",
    en_GB: "en-gb",
    en_US: "en", // L'anglais américain est le défaut
    es_ES: "es",
    ar_MA: "ar",
    nl_NL: "nl",
};

// Object d'associsations des countryCodeLanguage avec le fichier json portant le même nom.
type LanguageFilesType = {
    [x: string]: object;
};

// L'ordre n'a pas d'importance
export const languageFiles: LanguageFilesType = {
    fr_FR: frFR,
    en_GB: enGB,
    en_US: enUS,
    de_DE: deDE,
    es_ES: esES,
    ar_MA: arMA,
    nl_NL: nlNL,
};

type Type_languageCode =
    | "fr_FR"
    | "en_GB"
    | "en_US"
    | "de_DE"
    | "es_ES"
    | "ar_MA"
    | "nl_NL";

export type Type_languageFieldsForMatrix = {
    [K in `${string}${typeof LANGUAGE_NAME_DIVIDER}${Type_languageCode}`]?: string;
};
