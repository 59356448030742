import { generatePath, useNavigate, useParams } from "react-router-dom";

import { useLanguage } from "src/contexts/languages";

export const useRedirection = () => {
    const { language } = useLanguage();
    const navigate = useNavigate();
    const urlParams = useParams();

    const redirectToCgu = (replace?: boolean) => {
        navigate(`/${language}/cgu`, {
            replace: replace,
        });
    };

    const redirectToProjects = (replace?: boolean) => {
        navigate(generatePath(`/${language}/projects`, { ...urlParams }), {
            replace: replace,
        });
    };

    const redirectToAddCompany = (replace?: boolean) => {
        navigate(generatePath(`/${language}/add-company`, { ...urlParams }), {
            replace: replace,
        });
    };

    const redirectToMetadataForm = (replace?: boolean) => {
        navigate(generatePath(`/${language}/fill-metadata`, { ...urlParams }), {
            replace: replace,
        });
    };

    const redirectToLogin = (replace?: boolean) => {
        navigate(`/${language}/login`, {
            replace: replace,
        });
    };

    return {
        redirectToProjects,
        redirectToCgu,
        redirectToAddCompany,
        redirectToMetadataForm,
        redirectToLogin,
    };
};
